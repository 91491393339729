// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-refill-404-js": () => import("./../../../src/pages/refill/404.js" /* webpackChunkName: "component---src-pages-refill-404-js" */),
  "component---src-pages-refill-fill-js": () => import("./../../../src/pages/refill/fill.js" /* webpackChunkName: "component---src-pages-refill-fill-js" */),
  "component---src-pages-refill-history-js": () => import("./../../../src/pages/refill/history.js" /* webpackChunkName: "component---src-pages-refill-history-js" */),
  "component---src-pages-refill-home-js": () => import("./../../../src/pages/refill/home.js" /* webpackChunkName: "component---src-pages-refill-home-js" */),
  "component---src-pages-refill-js": () => import("./../../../src/pages/refill.js" /* webpackChunkName: "component---src-pages-refill-js" */),
  "component---src-pages-refill-login-js": () => import("./../../../src/pages/refill/login.js" /* webpackChunkName: "component---src-pages-refill-login-js" */),
  "component---src-pages-refill-order-js": () => import("./../../../src/pages/refill/order.js" /* webpackChunkName: "component---src-pages-refill-order-js" */),
  "component---src-pages-refill-register-js": () => import("./../../../src/pages/refill/register.js" /* webpackChunkName: "component---src-pages-refill-register-js" */),
  "component---src-pages-refill-scanner-js": () => import("./../../../src/pages/refill/scanner.js" /* webpackChunkName: "component---src-pages-refill-scanner-js" */),
  "component---src-pages-refill-topup-js": () => import("./../../../src/pages/refill/topup.js" /* webpackChunkName: "component---src-pages-refill-topup-js" */),
  "component---src-pages-section-about-js": () => import("./../../../src/pages/section/about.js" /* webpackChunkName: "component---src-pages-section-about-js" */),
  "component---src-pages-section-comingsoon-js": () => import("./../../../src/pages/section/comingsoon.js" /* webpackChunkName: "component---src-pages-section-comingsoon-js" */),
  "component---src-pages-section-home-js": () => import("./../../../src/pages/section/home.js" /* webpackChunkName: "component---src-pages-section-home-js" */),
  "component---src-pages-section-ourplatform-js": () => import("./../../../src/pages/section/ourplatform.js" /* webpackChunkName: "component---src-pages-section-ourplatform-js" */),
  "component---src-pages-section-ourwater-js": () => import("./../../../src/pages/section/ourwater.js" /* webpackChunkName: "component---src-pages-section-ourwater-js" */),
  "component---src-pages-section-why-js": () => import("./../../../src/pages/section/why.js" /* webpackChunkName: "component---src-pages-section-why-js" */)
}

